import React, { useEffect } from 'react'
import { Layout } from 'antd'
import { NavLink, Link } from 'react-router-dom'
import { IRouteProps } from '@/routes/config'
import { config } from '@/config'
import './index.less'

const { Header, Footer } = Layout

export interface ILayoutProps {
  menus: IRouteProps[]
}

export function MainLayout(props: React.PropsWithChildren<ILayoutProps>) {
  const hideMenu = () => {
    const header = document.querySelector('.header')
    // @ts-ignore
    header.classList.remove('menu-opened')
  }
  useEffect(() => {
    const burger = document.querySelector('.burger-container')
    const header = document.querySelector('.header')
    // @ts-ignore
    burger.onclick = function () {
      // @ts-ignore
      header.classList.toggle('menu-opened')
    }
  }, [])
  return (
    <Layout>
      <Header className="header flex">
        <Link to="/" className="logo">
          <img src={`${config.storageBaseUrl}/logo.jpeg`} alt="logo" />
        </Link>
        <div className="burger-container">
          <div id="burger">
            <div className="bar topBar" />
            <div className="bar btmBar" />
          </div>
        </div>
        <div className="menu" onClick={hideMenu}>
          <NavLink exact to="/" className="menu-item" activeClassName="selected">
            首页
          </NavLink>
          <NavLink to="/products" className="menu-item" activeClassName="selected">
            产品介绍
          </NavLink>
          <NavLink to="/company" className="menu-item" activeClassName="selected">
            公司介绍
          </NavLink>
          <NavLink to="/contact" className="menu-item" activeClassName="selected">
            联系我们
          </NavLink>
        </div>
      </Header>
      <div className="uchiwu-content">{props.children}</div>
      <Footer className="footer" style={{ textAlign: 'center' }}>
        <a rel="noopener noreferrer" target="_blank" href="https://beian.miit.gov.cn/">
          沪 ICP 备 20003429 号-4
        </a>
      </Footer>
    </Layout>
  )
}
